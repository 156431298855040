import Users from '../pages/notifications1/users/Index.vue';
import Tags from '../pages/notifications1/tags/Index.vue';
import Campaigns from '../pages/notifications1/campaigns/Index.vue';
import Templates from '../pages/notifications1/templates/Index.vue';
import Template from '../pages/notifications1/templates/Template.vue';

import Full from '../layout/Full.vue';
import mailEvents from '../pages/notifications1/mail_events/Index.vue';

export default {
  path: '/notifications',
  redirect: '/notifications/users',
  component: Full,
  children: [
    {
      name: 'notificationUsers',
      path: 'users',
      component: Users,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Пользователи рассылок',
        breadcrumbs: [
          { name: 'Рассылки' },
        ],
        permissions: ['notifications.users.view'],
      },
    },
    {
      name: 'notificationTags',
      path: 'tags',
      component: Tags,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Теги пользователей рассылок',
        breadcrumbs: [
          { name: 'Рассылки' },
        ],
        permissions: ['notifications.tags.view'],
      },
    },
    {
      name: 'notificationCampaigns',
      path: 'campaigns',
      component: Campaigns,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Кампании',
        breadcrumbs: [
          { name: 'Рассылки' },
        ],
        permissions: ['notifications.campaigns.view'],
      },
    },
    {
      name: 'notificationTemplates',
      path: 'templates',
      component: Templates,
      meta: {
        auth: true,
        scrollable: false,
        title: 'Шаблоны',
        breadcrumbs: [
          { name: 'Рассылки' },
        ],
        permissions: ['notifications.templates.view'],
      },
    },
    {
      name: 'notificationMailEvents',
      path: 'mail_events',
      component: mailEvents,
      meta: {
        auth: true,
        scrollable: false,
        title: 'События',
        breadcrumbs: [
          { name: 'Рассылки' },
        ],
        permissions: ['notifications.mail_events.view'],
      },
    },
    {
      name: 'notificationTemplate',
      path: 'templates/:templateId?',
      component: Template,
      meta: {
        auth: true,
        title: 'Редактирование шаблона',
        breadcrumbs: [
          { name: 'Рассылки' },
          { name: 'Шаблоны' },
        ],
        permissions: ['notifications.templates.edit'],
      },
    },
  ],
};
