<template>
  <div class="table-container notificationMailEvents">
    <collection-provider
      collection="notificationMailEvents"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionaries-provider :names="['notificationMailStatuses', 'notificationMailDeliveryStatuses', 'notificationCampaigns']">
          <template #default="{ notificationMailStatuses, notificationMailDeliveryStatuses, notificationCampaigns  }">
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :permissions="$user.permissions"
            >
              <template #filter(campaignId)>
                <b-form-select
                    size="sm"
                    :value="data.filter.campaignId"
                    :options="$array.convertToOptions(notificationCampaigns)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('campaignId', $event)"
                ></b-form-select>
              </template>
              <template #filter(status)>
                <b-form-select
                    size="sm"
                    :value="data.filter.status"
                    :options="$array.convertToOptions(notificationMailStatuses)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('status', $event)"
                ></b-form-select>
              </template>
              <template #filter(deliveryStatus)>
                <b-form-select
                    size="sm"
                    :value="data.filter.deliveryStatus"
                    :options="$array.convertToOptions(notificationMailDeliveryStatuses)"
                    :disabled="data.loading"
                    @change="actions.changeFilter('deliveryStatus', $event)"
                ></b-form-select>
              </template>
              <template #cell(campaignId)="data">
                {{ $array.convertToText(data.item.campaign) }}
                <div v-if="data.item.campaign">
                  {{ data.item.campaign.name }}
                </div>
                <div v-else>Неизвестная кампания с ID#{{ data.item.campaignId }}</div>
              </template>
              <template #cell(status)="data">
                {{$array.getItemText(notificationMailStatuses , data.item.status)}}
                <icon icon="fa-info" :title="$array.getItemText(notificationMailStatuses , data.item.status, 'description')"></icon>
              </template>
              <template #cell(deliveryStatus)="data">
                {{$array.getItemText(notificationMailDeliveryStatuses , data.item.deliveryStatus)}}
                <icon icon="fa-info" :title="$array.getItemText(notificationMailDeliveryStatuses , data.item.deliveryStatus, 'description')"></icon>
              </template>
            </datatable>
          </template>
        </dictionaries-provider>
      </template>
    </collection-provider>
  </div>
</template>

<script>
import DictionariesProvider from '@/providers/DictionariesProvider';

import fields from './_index.fields';

import Datatable from '../../../components/datatable/Datatable.vue';

import CollectionProvider from '../../../providers/CollectionProvider';

export default {
  components: {
    DictionariesProvider,
    CollectionProvider,
    Datatable,
  },
  data() {
    return {
      datatable: {
        fields,
      },
    };
  },
};
</script>

<style>
.notificationMailEvents {
  overflow-y: scroll;
  height: 100%;
  display: block;
}
</style>
