<template>
  <div class="table-container movieTags">
    <collection-provider
      collection="movieTags"
      ref="collection"
    >
      <template #default="{ data, actions }">
        <dictionary-provider name="movieTags">
          <template>
            <datatable
              v-bind="{ ...data, ...actions }"
              :fields="datatable.fields"
              :panel-actions="datatable.panelActions"
              :item-actions="datatable.itemActions"
              :checked="datatable.checked"
              :permissions="$user.permissions"
              @change-checked="datatable.checked = $event"
              @panel-action="performPanelAction($event)"
              @item-action="performItemAction($event)"
            >
            </datatable>
          </template>
        </dictionary-provider>
      </template>
    </collection-provider>

    <modal-model-form
      v-if="modals.entity.show"
      create-title="Создание тэга"
      edit-title="Редактирование тэга"
      collection="movieTags"
      :id="modals.entity.id"
      @close="modals.entity.show = false"
      @saved="$refs.collection.fetchItems()"
    >
      <template #default="{ data, actions }">
        <entity-form
          :loading="data.loading"
          :errors="data.errors"
          :states="data.states"
          :item="data.item"
          @change="actions.setValue($event.field, $event.value)"
        ></entity-form>
      </template>
    </modal-model-form>
  </div>
</template>

<script>
import { confirmDialog } from '../../utils/dialogs';

import fields from './_index.fields';
import itemActions from './_index.itemActions';
import panelActions from './_index.panelActions';

import Datatable from '../../components/datatable/Datatable.vue';

import DictionaryProvider from '../../providers/DictionaryProvider';
import CollectionProvider from '../../providers/CollectionProvider';
import ModalModelForm from '../../components/modal/ModalModelForm.vue';
import EntityForm from './EntityForm.vue';

export default {
  components: {
    DictionaryProvider,
    CollectionProvider,
    ModalModelForm,
    Datatable,
    EntityForm,
  },
  data() {
    return {
      datatable: {
        checked: [],
        fields,
        itemActions,
        panelActions,
      },
      modals: {
        entity: {
          show: false,
          id: 0,
        },
      },
    };
  },
  methods: {
    performPanelAction({ action, ids, args }) {
      this[action](ids, args);
    },

    performItemAction({ action, id, args }) {
      this[action](id, args);
    },

    openCreateTagForm() {
      this.modals.entity.id = '';
      this.modals.entity.show = true;
    },

    async removeCheckedTags() {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItems(this.datatable.checked);
        this.datatable.checked = [];
      }
    },

    openEditMoviesTagForm(id) {
      this.modals.entity.id = id;
      this.modals.entity.show = true;
    },

    async removeTag(id) {
      if (await confirmDialog('Удалить?')) {
        this.$refs.collection.removeItem(id);
      }
    },
  },
};
</script>
