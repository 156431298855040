<template>
  <div v-if="movie">
    <info-card>
      <info-card-row v-if="movie.countries">
        <template #label>{{ movie.countries.length > 1 ? 'Страны' : 'Страна' }}</template>
        <template #value>{{ $array.convertToText(movie.countries) }}</template>
      </info-card-row>

      <info-card-row v-if="movie.year">
        <template #label>Год</template>
        <template #value>{{ movie.year }}</template>
      </info-card-row>

      <info-card-row v-if="movie.datePreview">
        <template #label>Предпоказ в России</template>
        <template #value>{{ movie.datePreview | date }}</template>
      </info-card-row>

      <info-card-row v-if="movie.dateStart">
        <template #label>Старт проката в России</template>
        <template #value>
          с {{ movie.dateStart | date }}
          <span v-if="movie.dateEnd"> по {{ movie.dateEnd | date }}</span>
        </template>
      </info-card-row>

      <info-card-row v-if="movie.worldDateStart">
        <template #label>Старт проката в мире</template>
        <template #value>{{ movie.worldDateStart | date }}</template>
      </info-card-row>

      <template>
        <info-card-row >
          <template #label>Прокатчик</template>
          <template #value>
            {{ movie.distributor.name }}
          </template>
        </info-card-row>
      </template>

      <template v-for="(laboratory, index) in movie.laboratories">
        <info-card-row :key="`laboratory_${index}`">
          <template #label>{{ laboratory.type.name }}</template>
          <template #value>
            <span class="ttc">{{ laboratory.name }}</span>
          </template>
        </info-card-row>
      </template>

      <info-card-row v-if="movie.rentalLicenseNumber">
        <template #label>Прокатное удостоверение</template>
        <template #value>
          <b-link target="_blank" v-if="movie.rentalLicenseUrl" :href="movie.rentalLicenseUrl">{{ movie.rentalLicenseNumber }}</b-link>
          <span v-if="!movie.rentalLicenseUrl">{{ movie.rentalLicenseNumber }}</span>
          <span v-if="movie.rentalLicensePeriodStart"> от {{ movie.rentalLicensePeriodStart | date }}</span>
          <span v-if="movie.rentalLicensePeriodEnd"> до {{ movie.rentalLicensePeriodEnd | date }}</span>
        </template>
      </info-card-row>

      <info-card-row v-if="movie.unfNumber">
        <template #label>Уд-ние национального фильма</template>
        <template #value>
          <b-link target="_blank" v-if="movie.unfUrl" :href="movie.unfUrl">{{ movie.unfNumber }}</b-link>
          <span v-if="!movie.unfUrl">{{ movie.unfNumber }}</span>
          <span v-if="movie.unfPeriodStart"> от {{ movie.unfPeriodStart | date }}</span>
          <span v-if="movie.unfPeriodEnd"> до {{ movie.unfPeriodEnd | date }}</span>
        </template>
      </info-card-row>

      <info-card-row v-if="movie.memorandumUrl || movie.memorandumPeriodStart || movie.memorandumPeriodEnd" class="font-weight-bold text-danger">
        <template #label>Меморандум</template>
        <template #value>
          <b-link target="_blank" v-if="movie.memorandumUrl" :href="movie.memorandumUrl">Скачать</b-link>
          <span v-if="movie.memorandumPeriodStart"> от {{ movie.memorandumPeriodStart | date }}</span>
          <span v-if="movie.memorandumPeriodEnd"> до {{ movie.memorandumPeriodEnd | date }}</span>
        </template>
      </info-card-row>

      <info-card-row v-if="movie.genres">
        <template #label>{{ movie.genres.length > 1 ? 'Жанры' : 'Жанр' }}</template>
        <template #value>{{ $array.convertToText(movie.genres) }}</template>
      </info-card-row>

      <info-card-row v-if="movie.tags">
        <template #label>Тэги</template>
        <template #value>{{ $array.convertToText(movie.tags) }}</template>
      </info-card-row>

      <info-card-row v-if="movie.description">
        <template #label>Описание</template>
        <template #value>{{ movie.description }}</template>
      </info-card-row>

      <info-card-row v-if="movie.directors">
        <template #label>{{ movie.directors.length > 1 ? 'Режиссёры' : 'Режиссёр' }}</template>
        <template #value>{{ fixStrings(movie.directors) }}</template>
      </info-card-row>

      <info-card-row v-if="movie.actors">
        <template #label>Актеры</template>
        <template #value>{{ fixStrings(movie.actors) }}</template>
      </info-card-row>

      <info-card-row v-if="movie.kinopoiskRating">
        <template #label>Кинопоиск</template>
        <template v-if="movie.kinopoiskId" #value>
          <b-link :href="`//www.kinopoisk.ru/film/${movie.kinopoiskId}/`" target="_blank">{{ Number(movie.kinopoiskRating).toFixed(1) }}</b-link> из 10
        </template>
        <template v-else #value>
          {{ Number(movie.kinopoiskRating).toFixed(1) }} из 10
        </template>
      </info-card-row>

      <info-card-row v-if="movie.imdbRating">
        <template #label>IMDb</template>
        <template v-if="movie.imdbId" #value>
          <b-link :href="`//www.imdb.com/title/tt${movie.imdbId}/`" target="_blank">{{ Number(movie.imdbRating).toFixed(1) }}</b-link> из 10
        </template>
        <template v-else #value>
          {{ Number(movie.imdbRating).toFixed(1) }} из 10
        </template>
      </info-card-row>

      <info-card-row v-if="movie.copies">
        <template #label>Количество копий</template>
        <template #value>
          {{ movie.copies }}
        </template>
      </info-card-row>

      <info-card-row v-if="movie.duration">
        <template #label>Хронометраж</template>
        <template #value>
          {{ movie.duration }} мин {{ movie.durationApproved ? '(окончательно)' : null }}
        </template>
      </info-card-row>

      <info-card-row v-if="movie.durationTotal">
        <template #label>С роликами</template>
        <template #value>
          {{ movie.durationTotal }} мин
        </template>
      </info-card-row>
    </info-card>

    <info-card v-if="movie.integratedTrailers.length" title="Вшитые ролики" class="mt-4">
      <div
        v-for="trailer in movie.integratedTrailers"
        :key="trailer.id"
      >
        {{ formatTime(trailer.pivot.duration) }}
        —
        <router-link
          :to="{
            name: 'movie2',
            params: {
              movieId: trailer.id,
            },
            query: $route.query,
          }"
        >
          {{ trailer.name }}
        </router-link>
      </div>
    </info-card>

    <info-card v-if="formats.length" title="Форматы" class="mt-4">
      <template v-for="(format, index) in formats">
        <info-card-row :key="`format_${index}`">
          <template #label>Формат{{ format.names.length > 1 ? 'ы' : '' }}</template>
          <template #value>
            <div>
              <template v-for="name in format.names">
                <span class="format-item" :key="name">{{ name }}</span>
              </template>
            </div>
          </template>
        </info-card-row>

        <info-card-row :key="`duration_${index}`">
          <template #label>Продолжительность</template>
          <template #value>{{ format.duration }} мин</template>
        </info-card-row>

        <hr class="mb-4" :key="`hr_${index}`" />
      </template>
    </info-card>
  </div>
</template>

<script>
import moment from 'moment';

import InfoCard from './InfoCard.vue';
import InfoCardRow from './InfoCardRow.vue';

export default {
  components: {
    InfoCard,
    InfoCardRow,
  },
  props: {
    movie: {
      default: () => ({}),
    },
  },
  data() {
    return {
    };
  },
  computed: {
    formats() {
      return this.movie.releases.reduce((formats, release) => {
        const format = formats.find((_format) => _format.duration === release.duration);

        if (format) {
          if (release?.format?.name) {
            format.names.push(release?.format?.name);
          }
        } else {
          formats.push({
            duration: release.duration,
            names: release?.format?.name ? [release?.format?.name] : [],
          });
        }

        return formats;
      }, []);
    },
  },
  filters: {
    date(value) {
      return value ? moment(value).format('LL') : '';
    },
  },
  methods: {
    fixStrings(value) {
      return value.replace(/\s+([,])/g, '$1').replace(/,(?=[^\s])/g, ', ');
    },

    formatTime(seconds) {
      return seconds ? new Date(1000 * seconds).toISOString().substr(14, 5) : '00:00';
    },
  },
};
</script>

<style lang="scss" scoped>
.format-item:not(:last-child) {
  margin-right: 5px;

  &::after {
    content: ',';
  }
}
.ttc {
  text-transform: capitalize;
}
</style>
