export default [
  {
    key: 'campaignId',
    type: 'text',
    label: 'Кампания',
    filterable: true,
    sortable: false,
  },
  {
    key: 'fromEmail',
    type: 'text',
    label: 'От кого',
    filterable: false,
    sortable: false,
  },
  {
    key: 'email',
    type: 'text',
    label: 'Кому',
    filterable: true,
    sortable: false,
  },
  {
    key: 'status',
    type: 'text',
    label: 'Статус',
    filterable: true,
    sortable: false,
  },
  {
    key: 'deliveryStatus',
    type: 'text',
    label: 'Статус Доставки',
    filterable: true,
    sortable: false,
  },
  {
    key: 'eventTime',
    type: 'datetime',
    label: 'Время',
    filterable: false,
    sortable: true,
  },
  {
    key: 'jobId',
    type: 'text',
    label: 'Job ID',
    filterable: false,
    sortable: false,
  },
];
